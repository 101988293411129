import {Component, EventEmitter, Input, Output} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-ssh-detail',
  templateUrl: './ssh-detail.component.html'
})
export class SshServerDetailComponent extends BaseComponent {
  @Input()
  sshServerId:any;

  companyId:any;
  sshServer:any;
  testResult:any;

  @Output()
  persisted:EventEmitter<any> = new EventEmitter<any>();

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.companyId = this.authService.getCompanyId();

    if (this.sshServerId) {
      this.load(this.sshServerId);
    } else {
      this.route.params
        .subscribe((params: Params) => {
          let id:any = params['id'];
          if (id === '0') {
            this.createNew();
          } else {
            this.load(id);
          }
        });
    }
  }

  load(id:any) {
    this.http.get(`${this.env.e.url}/ssh/${id}`).subscribe(
      data => {
        this.sshServer = data as any;
        if (this.sshServer.company) {
          this.companyId = this.sshServer.company.id;
        }
        this.testConnection();
      }
    );
  }

  createNew() {
    this.sshServer = {
      host: 'localhost',
      port: 22,
      authenticationType: 'BASIC',
      credentialId: undefined
    };
  }

  save() {
    if (this.companyId && this.companyId !== '') {
      this.sshServer.company = {
        id: this.companyId
      }
    }

    if (!this.sshServer.id) {
      this.http.post(`${this.env.e.url}/ssh`, this.sshServer)
        .subscribe(
          data => {
            this.sshServer = data;
            this.alertService.info(`Created ${this.sshServer.name}`);
            this.persisted.emit({
              data: data,
              action: 'added'
            });
            this.testConnection();
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/ssh/${this.sshServer.id}`, this.sshServer)
        .subscribe(
          data => {
            this.sshServer = data;
            this.alertService.info(`Updated SSH settings`);
            this.persisted.emit({
              data: data,
              action: 'updated'
            });
            this.testConnection();
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/ssh/${this.sshServer.id}`)
      .subscribe(
        complete => {
          this.persisted.emit({
            data: this.sshServer,
            action: 'deleted'
          });
          this.alertService.info(`Deleted SSH Server ${this.sshServer.name}`);
          if (!this.sshServerId) {
            this.router.navigate(["/sshs"]);
          }
        }
      );
  }

  testConnection() {
    this.testResult = null;
    this.http.get(`${this.env.e.url}/ssh/${this.sshServer.id}/test`).subscribe(
      data => {
        this.testResult = data as any;
      }
    );
  }

  hasRole(role:string) {
    if (!role) {
      return true;
    }
    return this.authService.hasRole(role);
  }
}
