import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SshServerFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }
  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  neIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get neId():any {
    return this.neIdChange.value;
  }
  set neId(neId:any) {

    this.neIdChange.next(neId);
    this.emitChange("neId", neId);
  }

  portChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get port():any {
    return this.portChange.value;
  }
  set port(port:any) {

    this.portChange.next(port);
    this.emitChange("port", port);
  }

  credentialIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get credentialId():any {
    return this.credentialIdChange.value;
  }
  set credentialId(credentialId:any) {
    this.credentialIdChange.next(credentialId);
    this.emitChange("credentialId", credentialId);
  }

  authenticationTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get authenticationType():any {
    return this.authenticationTypeChange.value;
  }
  set authenticationType(authenticationType:any) {
    this.authenticationTypeChange.next(authenticationType);
    this.emitChange("authenticationType", authenticationType);
  }

  errorChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get error():any {
    return this.errorChange.value;
  }
  set error(error:any) {

    this.errorChange.next(error);
    this.emitChange("error", error);
  }

  errorDetailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get errorDetail():any {
    return this.errorDetailChange.value;
  }
  set errorDetail(errorDetail:any) {

    this.errorDetailChange.next(errorDetail);
    this.emitChange("errorDetail", errorDetail);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();
    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.neId && this.neId !== '') {
      params = params.set("neId", this.neId);
    }

    if (this.port && this.port !== '') {
      params = params.set("port", this.port);
    }

    if (this.credentialId && this.credentialId !== '') {
      params = params.set("credentialId", this.credentialId);
    }

    if (this.authenticationType && this.authenticationType !== '') {
      params = params.set("authenticationType", this.authenticationType);
    }

    if (this.error && this.error !== '') {
      params = params.set("error", this.error);
    }

    if (this.errorDetail && this.errorDetail !== '') {
      params = params.set("errorDetail", this.errorDetail);
    }

    return params;
  }

  public clear():void {
    this.companyId = undefined;
    this.name = undefined;
    this.neId = undefined;
    this.port = undefined;
    this.credentialId = undefined;
    this.authenticationType = undefined;
    this.error = undefined;
    this.errorDetail = undefined;
  }
}
