<div *ngIf="sshServer">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>computer</mat-icon> {{!sshServer.id ? 'Create new SSH connection' : ('SSH Connection ' + sshServer.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid || !sshServer.neId">
        <mat-icon>{{!sshServer.id ? 'add' : 'save'}}</mat-icon>
        {{(sshServer.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="sshServer.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>
  <div *ngIf="sshServer.id && !testResult">
    <mat-toolbar>
      Testing SSH connection...
    </mat-toolbar>
  </div>
  <div *ngIf="testResult && !testResult.success">
    <mat-toolbar class="primary">
      {{testResult.error}}
    </mat-toolbar>
  </div>
  <div *ngIf="testResult && testResult.success">
    <mat-toolbar style="background: green;color: white;">
      Connection was successful
    </mat-toolbar>
  </div>

  <div class="padded vbox-space">
    <div>
      <form #form="ngForm" role="form" class="vbox">
        <table class="basicTable vtable">
          <thead>
          <tr>
            <th colspan="100">SSH Server Configuration</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th>
              <micro-valid-label for="name" [model]="name" label="Name"></micro-valid-label>
            </th>
            <td><input #name="ngModel" [(ngModel)]="sshServer.name" [required]="true" placeholder="Name" id="name" name="name"></td>
          </tr>
          <tr>
            <th>
              <micro-valid-label for="ne" [model]="ne" label="NE"></micro-valid-label>
              </th>
            <td><micro-ne-select #ne="ngModel" mode="auto" [(ngModel)]="sshServer.neId" [required]="true" id="ne" name="ne"></micro-ne-select></td>
          </tr>
          <tr>
            <th>
              <micro-valid-label for="port" [model]="port" label="Port"></micro-valid-label>
            </th>
            <td><input #port="ngModel" type="number" min="0" max="65535" [(ngModel)]="sshServer.port" [required]="true" placeholder="Port" id="port" name="port"></td>
          </tr>
          <tr>
            <th>
              <micro-valid-label for="authenticationType" [model]="authenticationType" label="Authentication Type"></micro-valid-label>
            </th>
            <td><micro-sshAuthType-select #authenticationType="ngModel" [(ngModel)]="sshServer.authenticationType" id="authenticationType" name="authenticationType" [required]="true"></micro-sshAuthType-select></td>
          </tr>
          <tr>
            <th>
              <micro-valid-label for="credentialId" [model]="credentialId" label="Credentials"></micro-valid-label>
            </th>
            <td><micro-credential-select #credentialId="ngModel" [credentialAuthType]="sshServer.authenticationType" [(ngModel)]="sshServer.credentialId" [companyId]="sshServer.companyId" [required]="true" id="credentialId" name="credentialId"></micro-credential-select></td>
          </tr>
          <tr>
            <th>
              Debug SSH Session
            </th>
            <td><micro-yesNo-select [(yesNo)]="sshServer.debugSshSession" [allLabel]="'Select yes/no'" [required]="true" id="debugSshSession" name="debugSshSession"></micro-yesNo-select></td>
          </tr>
          </tbody>
        </table>
      </form>
    </div>

    <micro-objectGroup-picklist type="SSH_SERVER" [(selectedItems)]="sshServer.sshServerGroups"></micro-objectGroup-picklist>
  </div>
</div>
