<div *ngIf="credential">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>key</mat-icon> {{!credential.id ? 'Create new Credential' : ('Credential ' + credential.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!credential.id ? 'add' : 'save'}}</mat-icon>
        {{(credential.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="credential.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded vbox-space">
    <div>
      <form #form="ngForm" role="form" class="vbox">
        <table class="basicTable vtable">
          <thead>
          <tr>
            <th colspan="100">Credential Configuration</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th><micro-valid-label for="name" [model]="name" label="Name"></micro-valid-label></th>
            <td>
              <input style="width: 350px;" #name="ngModel" [(ngModel)]="credential.name" required placeholder="Name" id="name" name="name">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="description" [model]="description" label="Description"></micro-valid-label></th>
            <td>
              <input #description="ngModel" [(ngModel)]="credential.description" placeholder="Description" id="description" name="description">
            </td>
          </tr>
          <tr *ngIf="authService.isSystemUser()">
            <th><micro-valid-label for="companyId" [model]="description" label="Company"></micro-valid-label></th>
            <td>
              <micro-company-select [(companyId)]="credential.companyId" required></micro-company-select>
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="credentialAuthType" [model]="credentialAuthType" label="Type"></micro-valid-label></th>
            <td>
              <micro-credentialAuthType-select #credentialAuthType="ngModel" [(ngModel)]="credential.credentialAuthType" [required]="true" id="credentialAuthType" name="credentialAuthType"  (credentialAuthTypeChange)="authTypeChange()"></micro-credentialAuthType-select>
            </td>
          </tr>
          <ng-container *ngIf="credential.credentialAuthType === 'BASIC'">
            <tr>
              <th><micro-valid-label for="basicUsername" [model]="basicUsername" label="Username"></micro-valid-label></th>
              <td><input #basicUsername="ngModel" [(ngModel)]="credential.authCredentials.username" required placeholder="Username" id="basicUsername" name="basicUsername"></td>
            </tr>
            <tr *ngIf="credential.id && !newBasicAuth">
              <th>Change Password</th>
              <td>
                <input [(ngModel)]="changeBasicPassword" type="checkbox" id="changePassword" name="changePassword">
              </td>
            </tr>
            <tr *ngIf="changeBasicPassword || !credential.id || newBasicAuth">
              <th><micro-valid-label for="basicPassword" [model]="basicPassword" label="Password"></micro-valid-label></th>
              <td>
                <input type="password" #basicPassword="ngModel" [(ngModel)]="credential.authCredentials.clearPassword" required placeholder="Password" id="basicPassword" name="basicPassword">
              </td>
            </tr>
          </ng-container>

          <ng-container *ngIf="credential.credentialAuthType === 'OAUTH2'">
            <tr>
              <th>Grant Type</th>
              <td><micro-oauth2-grant-type-select [(grantType)]="credential.authCredentials.grantType" [required]="true"></micro-oauth2-grant-type-select></td>
            </tr>
            <tr>
              <th><micro-valid-label for="accessTokenUrl" [model]="accessTokenUrl" label="Access Token URL"></micro-valid-label></th>
              <td><input #accessTokenUrl="ngModel" [(ngModel)]="credential.authCredentials.accessTokenUrl" [required]="true" placeholder="OAuth2 token URL" id="accessTokenUrl" name="accessTokenUrl"></td>
            </tr>
            <tr>
              <th><micro-valid-label for="oauth2ClientId" [model]="oauth2ClientId" label="Client ID"></micro-valid-label></th>
              <td><input #oauth2ClientId="ngModel" [(ngModel)]="credential.authCredentials.clientId" placeholder="OAuth2 Client ID" [required]="credential.authCredentials.grantType === 'CLIENT_CREDENTIALS'" id="oauth2ClientId" name="oauth2ClientId"></td>
            </tr>
            <tr *ngIf="credential.id && !newOAuth2">
              <th>Change Client Secret</th>
              <td>
                <input [(ngModel)]="changeOAuth2ClientSecret" type="checkbox" id="changeClientSecret" name="changeClientSecret">
              </td>
            </tr>
            <tr *ngIf="changeOAuth2ClientSecret || !credential.id || newOAuth2">
              <th><micro-valid-label for="oauth2ClientSecret" [model]="oauth2ClientSecret" label="Client Secret"></micro-valid-label></th>
              <td>
                <input type="password" #oauth2ClientSecret="ngModel" [(ngModel)]="credential.authCredentials.clearClientSecret" placeholder="OAuth2 Client Secret" [required]="credential.authCredentials.grantType === 'CLIENT_CREDENTIALS'" id="oauth2ClientSecret" name="oauth2ClientSecret">
              </td>
            </tr>
            <ng-container *ngIf="credential.authCredentials.grantType === 'PASSSWORD_CREDENTIALS'">
              <tr>
                <th><micro-valid-label for="oauth2Username" [model]="oauth2Username" label="Username"></micro-valid-label></th>
                <td><input #oauth2Username="ngModel" [(ngModel)]="credential.authCredentials.username" [required]="credential.authCredentials.grantType === 'PASSSWORD_CREDENTIALS'" placeholder="Username" id="oauth2Username" name="oauth2Username"></td>
              </tr>
              <tr *ngIf="credential.id && !newOAuth2">
                <th>Change Password</th>
                <td>
                  <input [(ngModel)]="changeOAuth2Password" type="checkbox" id="changeOAuth2Password" name="changeOAuth2Password">
                </td>
              </tr>
              <tr *ngIf="changeOAuth2Password || !credential.id || newOAuth2">
                <th><micro-valid-label for="oauth2Password" [model]="oauth2Password" label="Password"></micro-valid-label></th>
                <td>
                  <input type="password" #oauth2Password="ngModel" [(ngModel)]="credential.authCredentials.clearPassword" [required]="credential.authCredentials.grantType === 'PASSSWORD_CREDENTIALS'" placeholder="Password" id="oauth2Password" name="oauth2Password">
                </td>
              </tr>
            </ng-container>
            <tr>
              <th><micro-valid-label for="oauth2Scope" [model]="oauth2Scope" label="Scope"></micro-valid-label></th>
              <td><input #oauth2Scope="ngModel" [(ngModel)]="credential.authCredentials.scope" placeholder="OAuth2 scope" id="oauth2Scope" name="oauth2Scope"></td>
            </tr>
            <tr>
              <th>Auth Credentials Method</th>
              <td><micro-oauth2-auth-credentials-method-select [(method)]="credential.authCredentials.authCredentialsMethod" [required]="true"></micro-oauth2-auth-credentials-method-select></td>
            </tr>
            <tr>
              <th><micro-valid-label for="oauth2AuthHeaderPrefix" [model]="oauth2AuthHeaderPrefix" label="Token Auth Header Prefix"></micro-valid-label></th>
              <td><input #oauth2AuthHeaderPrefix="ngModel" [(ngModel)]="credential.authCredentials.authHeaderPrefix" placeholder="Access token Auth header prefix" id="oauth2AuthHeaderPrefix" name="oauth2AuthHeaderPrefix"></td>
            </tr>
          </ng-container>


          <ng-container *ngIf="credential.credentialAuthType === 'API_KEY'">
            <tr>
              <th><micro-valid-label for="apikeyKey" [model]="apikeyKey" label="Key/Name"></micro-valid-label></th>
              <td><input #apikeyKey="ngModel" [(ngModel)]="credential.authCredentials.key" required placeholder="API key name" id="apikeyKey" name="apikeyKey"></td>
            </tr>
            <tr>
              <th><micro-valid-label for="apikeyValue" [model]="apikeyValue" label="Value"></micro-valid-label></th>
              <td>
                <input #apikeyValue="ngModel" [(ngModel)]="credential.authCredentials.value" required placeholder="API key value" id="apikeyValue" name="apikeyValue">
              </td>
            </tr>
            <tr>
              <th><micro-valid-label for="apikeyHttpHeader" [model]="apikeyHttpHeader" label="Use as HTTP Header"></micro-valid-label></th>
              <td>
                <input #apikeyHttpHeader="ngModel" [(ngModel)]="credential.authCredentials.httpHeader" type="checkbox" id="apikeyHttpHeader" name="apikeyHttpHeader">
              </td>
            </tr>
          </ng-container>

          <ng-container *ngIf="credential.credentialAuthType === 'BEARER_TOKEN'">
            <tr>
              <th><micro-valid-label for="bearerPrefix" [model]="bearerPrefix" label="Token Prefix"></micro-valid-label></th>
              <td><input #bearerPrefix="ngModel" [(ngModel)]="credential.authCredentials.prefix" placeholder="Bearer (default)" id="bearerPrefix" name="bearerPrefix"></td>
            </tr>
            <tr>
              <th><micro-valid-label for="bearerToken" [model]="bearerToken" label="Token"></micro-valid-label></th>
              <td>
                <input #bearerToken="ngModel" [(ngModel)]="credential.authCredentials.value" required placeholder="Token value" id="bearerToken" name="bearerToken">
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="credential.credentialAuthType === 'PUBKEY'">
            <tr>
              <th><micro-valid-label for="pubkeyUsername" [model]="pubkeyUsername" label="Username"></micro-valid-label></th>
              <td><input #pubkeyUsername="ngModel" [(ngModel)]="credential.authCredentials.username" required placeholder="Username" id="pubkeyUsername" name="pubkeyUsername"></td>
            </tr>
            <tr *ngIf="credential.id && !newPubKeyAuth">
              <th>Change Private Key Passphrase</th>
              <td>
                <input [(ngModel)]="changePubKeyPassphrase" type="checkbox" id="changePubkeyPassphrase" name="changePubkeyPassphrase">
              </td>
            </tr>
            <tr *ngIf="changePubKeyPassphrase || !credential.id || newPubKeyAuth">
              <th><micro-valid-label for="pubkeyPassphrase" [model]="pubkeyPassphrase" label="Privare Key Passphrase"></micro-valid-label></th>
              <td>
                <input type="password" #pubkeyPassphrase="ngModel" [(ngModel)]="credential.authCredentials.clearPassphrase" placeholder="Passphrase" id="pubkeyPassphrase" name="pubkeyPassphrase">
              </td>
            </tr>
            <tr *ngIf="credential.id && !newPubKeyAuth">
              <th>Change Private Key</th>
              <td>
                <input [(ngModel)]="changePubKeyPrivateKey" type="checkbox" id="changePubkeyPrivateKey" name="changePubkeyPrivateKey">
              </td>
            </tr>
            <tr *ngIf="changePubKeyPrivateKey || !credential.id || newPubKeyAuth">
              <th><micro-valid-label for="pubkeyPrivateKey" [model]="pubkeyPrivateKey" label="Private Key"></micro-valid-label></th>
              <td>
                <textarea rows="10" #pubkeyPrivateKey="ngModel" [(ngModel)]="credential.authCredentials.clearPrivateKey" required placeholder="Private Key" id="pubkeyPrivateKey" name="pubkeyPrivateKey"></textarea>
              </td>
            </tr>
            <tr>
              <th><micro-valid-label for="pubkeyPublicKey" [model]="pubkeyPublicKey" label="Public Key"></micro-valid-label></th>
              <td>
                <textarea rows="10" #pubkeyPublicKey="ngModel" [(ngModel)]="credential.authCredentials.publicKey" required placeholder="Public Key" id="pubkeyPublicKey" name="pubkeyPublicKey"></textarea>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
