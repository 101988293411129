<div class="stick-top">
  <mat-toolbar>
    <mat-icon>check_circle</mat-icon> Service Monitor Checks
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/smcs/0">
      <mat-icon>add</mat-icon>
      Create a new Check
    </button>
    <span class="fill-space"></span>
    <button mat-raised-button class="primary" type="button" (click)="reload()">Refresh</button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th style="width: 75px;">
        <div class="vbox">
          Group
          <micro-objectGroup-select [(objectGroupId)]="filter.smGroupId" type="SM_CHECK"></micro-objectGroup-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Name
          <input [(ngModel)]="filter.name">
        </div>
      </th>
      <th style="width: 30px;">
        <div class="vbox">
          Run
        </div>
      </th>
      <th style="width: 75px;">
        <div class="vbox">
          MOC
          <input [(ngModel)]="filter.alarmMoClass">
        </div>
      </th>
      <th style="width: 200px;">
        <div class="vbox">
          SPEC.PROB
          <input [(ngModel)]="filter.alarmSpecProb">
        </div>
      </th>
      <th style="width: 40px;">
        <div class="vbox">
          Type
          <micro-serviceType-select [(serviceType)]="filter.serviceType"></micro-serviceType-select>
        </div>
      </th>
      <th matTooltip="STATUS" style="width: 20px;">
        <div class="vbox" >
          ST
          <select [(ngModel)]="filter.status">
            <option value="">ALL</option>
            <option value="SUCCESS">SUCCESS</option>
            <option value="ERROR">ERROR</option>
            <option value="DISABLED">DISABLED</option>
            <option value="PENDING">PENDING</option>
            <option value="INTERRUPTED">INTERRUPTED</option>
            <option value="NEW">NEW</option>
          </select>
        </div>
      </th>
      <th style="width: 20px;">
        <div matTooltip="INTERVAL" class="vbox">
          INT
        </div>
      </th>
      <th class="dateTimeHeader">
        <div class="vbox">
          Last Run
        </div>
      </th>
      <th class="dateTimeHeader">
        <div class="vbox">
          Next Run
        </div>
      </th>
      <th style="width: 120px">
        <div class="vbox">
          Target
        </div>
      </th>
      <th style="width: 120px">
        <div class="vbox">
          Log Retention (hrs)
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td><micro-objectGroup-lookup [objectGroupId]="element.smGroups"></micro-objectGroup-lookup></td>
      <td><a routerLink="/smcs/{{element.id}}">{{element.name}}</a></td>
      <td>
        <button mat-raised-button type="button" (click)="schedule(element)" [disabled]="!element.enabled || (element.protected && !element.__permEXECUTE)"
                [matTooltip]="element.enabled ? (element.protected && !element.__permEXECUTE ? 'Denied' : 'Run') : 'Disabled'">
          Run
        </button>
      </td>
      <td [matTooltip]="lookupService.lookup('domain', 'mocs', element.alarmMocId)">{{lookupService.lookup('domain', 'mocs', element.alarmMocId)}}</td>
      <td [matTooltip]="lookupService.lookup('domain', 'specProbs', element.alarmSpecProbId)">{{lookupService.lookup('domain', 'specProbs', element.alarmSpecProbId)}}</td>
      <td [matTooltip]="element.serviceType">{{element.serviceType}}</td>
      <td style="text-align: center;" [style.background-color]="element.enabled && element.status === 'ERROR' ? 'red' : ''" [matTooltip]="element.enabled ? element.status === 'ERROR' ? element.error : element.status : 'DISABLED'">
        {{element.enabled ? element.status.substring(0,1) : 'D'}}
        <mat-progress-bar *ngIf="element.status === 'PENDING' && element.enabled" mode="indeterminate"></mat-progress-bar>
      </td>
      <td style="text-align: center;"><micro-cron mode="list" [(value)]="element.schedule"></micro-cron></td>
      <td>{{element.lastRun | dateTime}}</td>
      <td>
        <span *ngIf="element.enabled">{{element.nextExecutionTime | dateTime}}</span>
        <span *ngIf="!element.enabled">DISABLED</span>
      </td>
      <td>
        <div *ngIf="element.targetType === 'GROUP'" class="hbox-nowrap">
          <div>G-</div>
          <div><micro-objectGroup-lookup [objectGroupId]="element.targetGroupId"></micro-objectGroup-lookup></div>
        </div>
        <div *ngIf="element.targetType === 'SINGLE'" class="hbox-nowrap">
          <div>S-</div>
          <div><micro-cmd-service-lookup [serviceType]="element.serviceType" [objectId]="element.targetId"></micro-cmd-service-lookup></div>
        </div>
      </td>
      <td>
        {{element.logRententionHours}}
      </td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
